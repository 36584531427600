import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" >


<path d="M2943 2200 c-16 -6 -23 -18 -23 -35 0 -21 8 -30 46 -46 25 -11 43
-26 41 -32 -6 -18 -42 -20 -57 -2 -18 22 -32 19 -28 -7 4 -32 59 -46 93 -24
40 26 32 54 -20 76 -50 21 -61 50 -20 50 14 0 28 -7 31 -15 8 -19 34 -20 34
-1 0 30 -59 52 -97 36z"/>
<path d="M3210 2125 l0 -84 40 3 c52 4 70 21 70 62 0 37 -35 69 -64 58 -12 -5
-16 0 -16 20 0 16 -6 26 -15 26 -12 0 -15 -16 -15 -85z m75 -4 c8 -26 -5 -53
-24 -49 -29 6 -27 68 3 68 8 0 17 -8 21 -19z"/>
<path d="M3710 2195 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M4010 2195 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16
-13z"/>
<path d="M3930 2185 c0 -8 -4 -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 5 -15 10
-15 6 0 10 -20 10 -44 0 -31 5 -46 16 -50 25 -10 34 -7 34 9 0 8 -4 15 -10 15
-18 0 -12 67 8 75 16 6 16 7 0 19 -10 7 -18 18 -18 25 0 6 -7 11 -15 11 -8 0
-15 -7 -15 -15z"/>
<path d="M3070 2116 c0 -61 9 -73 63 -73 l37 -1 0 64 c0 50 -3 64 -15 64 -11
0 -15 -12 -15 -50 0 -43 -3 -50 -20 -50 -17 0 -20 7 -20 50 0 38 -4 50 -15 50
-11 0 -15 -13 -15 -54z"/>
<path d="M3368 2159 c-31 -17 -22 -47 19 -64 26 -11 33 -19 26 -26 -8 -8 -17
-7 -32 1 -34 18 -49 6 -18 -15 35 -23 80 -11 85 22 2 16 -5 24 -32 33 -47 15
-48 34 -1 26 28 -6 34 -4 29 8 -3 8 -15 17 -27 20 -28 7 -29 7 -49 -5z"/>
<path d="M3490 2150 c-11 -11 -20 -31 -20 -45 0 -29 32 -65 58 -65 10 0 27 6
38 14 16 12 17 17 5 28 -11 10 -14 10 -18 1 -3 -7 -13 -13 -23 -13 -21 0 -34
29 -24 54 7 18 39 22 49 6 9 -15 25 -12 25 5 0 16 -30 35 -54 35 -9 0 -25 -9
-36 -20z"/>
<path d="M3610 2104 c0 -50 3 -64 15 -64 11 0 15 11 15 40 0 29 5 43 20 52 30
19 24 34 -15 35 l-35 1 0 -64z"/>
<path d="M3710 2105 c0 -51 3 -65 15 -65 12 0 15 14 15 65 0 51 -3 65 -15 65
-12 0 -15 -14 -15 -65z"/>
<path d="M3774 2156 c-10 -26 1 -159 14 -164 8 -2 12 7 12 27 0 28 3 31 24 25
49 -12 85 57 52 101 -16 22 -95 30 -102 11z m81 -35 c8 -26 -5 -53 -24 -49
-10 2 -17 15 -19 36 -3 26 0 32 17 32 11 0 22 -8 26 -19z"/>
<path d="M4010 2105 c0 -51 3 -65 15 -65 12 0 15 14 15 65 0 51 -3 65 -15 65
-12 0 -15 -14 -15 -65z"/>
<path d="M4102 2154 c-44 -30 -18 -114 35 -114 23 0 53 39 53 69 0 29 -29 61
-55 61 -6 0 -21 -7 -33 -16z m53 -49 c0 -23 -4 -30 -20 -30 -21 0 -31 25 -21
51 11 28 41 12 41 -21z"/>
<path d="M4220 2104 c0 -50 3 -64 15 -64 11 0 15 12 15 50 0 42 3 50 19 50 22
0 31 -19 31 -66 0 -25 4 -34 16 -34 14 0 16 9 12 55 -3 30 -8 58 -11 62 -4 4
-27 8 -52 9 l-45 3 0 -65z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
